/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'f7wmP4Tb55MP9YcPpjThmN.png': { uri: '/images/f7wmP4Tb55MP9YcPpjThmN.png' },
'8Bgif2hdd9TAwYHENduk1X.png': { uri: '/images/8Bgif2hdd9TAwYHENduk1X.png' },
'37xVTMoa9a1uJ9dPSRnSLx.jpg': { uri: '/images/37xVTMoa9a1uJ9dPSRnSLx.jpg' },
'puhMhZRDn7roov5k31ttVi.jpg': { uri: '/images/puhMhZRDn7roov5k31ttVi.jpg' },
'gGTwunVMfrekBSHXiz8xmC.png': { uri: '/images/gGTwunVMfrekBSHXiz8xmC.png' },
'emvAMNp1oiCYw2t85Fh9UQ.png': { uri: '/images/emvAMNp1oiCYw2t85Fh9UQ.png' },
'w4wymMijkT841YhnaxrgTx.png': { uri: '/images/w4wymMijkT841YhnaxrgTx.png' },
'u82Z4xiKvG1q2AyZE13FiR.jpg': { uri: '/images/u82Z4xiKvG1q2AyZE13FiR.jpg' }
}

export default imageStaticSourcesByFileName
